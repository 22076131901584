import React from "react";
import { NavLink } from "react-router-dom"

export const Navbar = () => {
    return (
        <nav>
            <div className="navbar">
                <ul className="navlinks">
                    <li className="navelement" ><NavLink to="/">Главная</NavLink></li>
                    <li className="navelement" ><NavLink to="/recipes">Рецепты</NavLink></li>
                    <li className="navelement" ><NavLink to="/plan">План готовки</NavLink></li>
                    <li className="navelement" ><NavLink to="/shoppinglist">Список покупок</NavLink></li>
                    <li className="navelement" ><NavLink to="/contacts">Контакты</NavLink></li>
                </ul>
            </div>
        </nav>
    )
}
import React from "react";
import { Routes, Route } from "react-router-dom"
import { ContactsPage } from "./pages/about";
import { MainPage } from "./pages/main";
import { PlanPage } from "./pages/plan";
import { RecipesPage } from "./pages/recipes";
import { ShoppingListPage } from "./pages/shoppingList";

export const useRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<MainPage />}></Route>
            <Route path="/recipes" element={<RecipesPage />}></Route>
            <Route path="/plan" element={<PlanPage />}></Route>
            <Route path="/shoppinglist" element={<ShoppingListPage />}></Route>
            <Route path="/contacts" element={<ContactsPage />}></Route>
        </Routes>
    )
}
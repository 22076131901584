import { useEffect, useState } from "react"

export const Recipes = () => {
    const [data, setData] = useState([])
    var reqState = ''

    useEffect(() => {
        if (reqState === 'saved' ) {
            return
        }
        
        let res_data = localStorage.getItem('recipes')
        console.log('persistent --- ', res_data);
        reqState = 'requested'
        if (res_data) {
            console.log("Persistent data was used");
            setData(JSON.parse(res_data))
            reqState = 'saved'
        } else {
            fetch("https://cnt.recipeplanner.ru/recipes2")
            .then((response) => response.json())
            .then((data) => {
                console.log('online --- ',data);
                setData(data[0])
                localStorage.setItem("recipes", JSON.stringify(data[0]))
                reqState = 'saved'
            })
            .catch((err) => {
                console.log(err.message);
            })
        }
        
        
        
        // const dataFetch = async () => {
        //     const data = await (
        //         await fetch(
        //             "https://cnt.recipeplanner.ru/recipes"
        //         )
        //     ).json()
        //     console.log(data);
         
        // }

        // dataFetch()
    },[]) 
    // console.log('---',data);

    // data.map((recipe, id) => {
    //     console.log(id);
    //     console.log(recipe);
    //     return(
    //         <div className="container" id={recipe.id}>{recipe.id} {recipe.description} {recipe.tags} </div>
    //     )
    // } )

    return (
        <div className="container">
            <p className="reci">Список рецептов:</p>
            {data.map(recipe => {
                return(
                    <div className="container" key={recipe.id}>
                        {recipe.id} {recipe.name}
                    </div>
                    
                )
            })}

        </div>
    )
}
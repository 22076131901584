// import logo from './logo.svg';
// import './App.css';

import React from "react";
import { BrowserRouter as Router, useSearchParams } from "react-router-dom"
import { Navbar } from "./components/Navbar";
import { useRoutes } from "./routes";


function App() {
  const routes = useRoutes()

  return (
    <Router>
      <Navbar />
      <div className="container">
        {routes}
      </div>
    </Router>
  );
}

export default App;
